<template>
  <div class="pass-content">
    <div v-for="pass in passes" :key="pass.start_time" class="pass-item">
      <div class="d-flex jc-sb align-center">
        <div class="passed-car">
          {{ pass.brand ? pass.brand : "" }}
          <span v-if="pass.brand">•</span>
          {{ pass.state_number ? pass.state_number : "Номер машины" }}
        </div>
        <v-btn icon color="error" class="mb-1" @click="openDeletePass(pass)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>
      <div class="pass-reason">
        {{ pass.who_is }}
      </div>
      <div class="block-apartment-info">
        <v-chip v-if="pass.building.block" label class="block-info">
          <div class="type mr-1">Блок:</div>
          <div class="num">
            {{ pass.building.block }}
          </div>
        </v-chip>
        <v-chip v-if="pass.building.number" label class="apartment-info">
          <div class="type mr-1">Квартира:</div>
          <div class="num">
            {{ pass.building.number }}
          </div>
        </v-chip>
      </div>
      <div class="arrive-depart-time">
        <div class="arrive mr-6">
          <span class="arrive-title">Создан:</span>
          <div class="arrive-time">
            {{ $moment(pass.start_time).format("DD.MM.YY в HH:mm") }}
          </div>
        </div>
        <div class="depart">
          <span class="depart-title">Действует до:</span>
          <div class="depart-time">
            {{ $moment(pass.end_time).format("DD.MM.YY в HH:mm") }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="passes.length == 0" style="margin: auto" class="title">
      Жители еще не запрашивали пропуски
    </div>
    <DeletionConfirm
      v-if="deleteConfirm"
      :title="deleteTitle"
      :description="deleteDescription"
      @close="closeDeletion"
      @delete="deletePass"
    />
  </div>
</template>

<script>
import { getPassesApi, deletePassApi } from "@/api/security";
import DeletionConfirm from "@/components/modals/DeletionConfirm.vue";

export default {
  components: {
    DeletionConfirm,
  },
  data: () => ({
    passes: [],
    deleteConfirm: false,
    passToDelete: null,
    deleteTitle: "",
    deleteDescription: "",
  }),
  created() {
    this.getPasses();
  },
  methods: {
    async getPasses() {
      try {
        let res = await getPassesApi();
        this.passes = [...res.data];
        this.passes.forEach((pass) => {
          let end_time = this.$moment(pass.start_time).add(pass.validity, 'minutes');
          pass.end_time = end_time._d;
        });
      } catch (err) {
        throw err;
      };
    },
    openDeletePass(pass) {
      this.passToDelete = pass;
      this.deleteTitle = "Вы действительно хотите удалить пропуск";
      this.deleteDescription = `${pass.brand} ${pass.state_number} для "${pass.who_is}"?`;
      this.deleteConfirm = true;
    },
    closeDeletion() {
      this.passToDelete = null;
      this.deleteConfirm = false;
    },
    async deletePass() {
      try {
        let pass_id = this.passToDelete.pk;
        let res = await deletePassApi({ pass_id });
        this.closeDeletion();
        this.getPasses();
        if (res.data.message) {
          let serverMessage = res.data.message;
          this.$store.commit('SHOW_SNACKBAR', 'deletePassSuccessSnackbar');
          this.$store.commit('SET_SNACKBAR_TITLE', serverMessage);
          this.$store.commit('SET_SNACKBAR_COLOR', 'success');
        } else {
          this.$store.commit('SHOW_SNACKBAR', 'deletePassSuccessSnackbar');
          this.$store.commit('SET_SNACKBAR_TITLE', 'Пропуск успешно удалён');
          this.$store.commit('SET_SNACKBAR_COLOR', 'success');
        }
      } catch (err) {
        this.$store.commit('SHOW_SNACKBAR', 'deletePassErrorSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Ошибка при удалении пропуска');
        this.$store.commit('SET_SNACKBAR_COLOR', 'error');
        throw err;
      };
    },
  },
};
</script>

<style>
</style>