import { http } from './http'
import store from '@/store/index'

const baseCall = async (url, method = 'get', name, data, headersProp = {}) => {
  store.commit('ADD_LOADING', name);
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
    ...headersProp,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.request({ method, url, data, headers })
    .catch((e) => {
      store.commit('DEL_LOADING', name);
      return Promise.reject(e);
    });
  store.commit('DEL_LOADING', name);
  return res;
};

export const getPassesApi = async () => {
  const name = 'security.getPassesApi';
  const url = '/v1/buildings/pass';
  return baseCall(url, 'get', name)
}

export const deletePassApi = async (data) => {
  const name = 'security.deletePassApi';
  const url = '/v1/buildings/pass';
  return baseCall(url, 'delete', name, data)
}